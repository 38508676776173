// HEADER MASQUÉ PAR DÉFAUT S'AFFICHE AU SCROLL
$(window).scroll(function() {
    var value = jQuery(this).scrollTop();
    if ( value > 50 ) {
        $("#remonte").css('display','block').css( "opacity","0.8");
    } else {
        $("#remonte").css( "opacity","0").css('display','none');
    }
    
});

$('.remonte').on('click',function (event) {
    event.preventDefault();
    $('html, body').animate({
        scrollTop: 0
    }, 500);
});

$(function() {        
    wow = new WOW(
      {
        animateClass: 'animated',
        offset:       100,
        callback:     function(box) {
          console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        }
      }
    );
    wow.init();
    $('[data-toggle="tooltip"]').tooltip();
    
    $('[data-toggle="popover"]').popover();
    
    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
    });
    
    $(".lightGallery").lightGallery({
        selector: '.selector'
    });
    
    $('table').each(function(){
       $(this).find('td').css('padding',$(this).attr('cellpadding')+'px');
       $(this).find('td').css('margin',$(this).attr('cellspacing')+'px');
    });

    $('[data-link]').on('click', function(e) {
        e.preventDefault();
        if ($(this).data('target')=="new") {
            var win = window.open($(this).data('link'), '_blank');
              win.focus();
        }
        else { location.href = $(this).data('link'); }
        
    });
    
    $('.same-height').matchHeight();
    $('#hideseeksearch').hideseek({
        list: '.hideseek-list',
        highlight: true,
		ignore_accents: true
    });
    
    $('.grid').colcade({
        columns: '.grid-col',
        items: '.grid-item'
    });
    
    
    $(".radio-switch").bootstrapSwitch({
        size : 'small'
    });

});